<template>
  <div>
    <v-row>
      <v-col lg="12">  
          <prf-layette-incidence></prf-layette-incidence>
          <!-- <prf-layette-incidence :DedID="dedID" :height="200"></prf-layette-incidence>        -->        
          
        </v-col>
     <!-- <v-col lg="6">
         <sub-zone :isAdmin="true"  :DedID="dedID" :height="200"> >
        </sub-zone> 
             
      </v-col>-->
  
      
     
    </v-row>
  </div>
</template>

<script>
import PrfLayetteIncidence from './PrfLayetteIncidence.vue';
    
export default {
  components: {
    PrfLayetteIncidence
  },

   data() {
    return {
      parentID: 0,
      dedID:0,
     
    };
  },

  methods: {
    rowSelected(rows) {
     this.dedID=rows[0].LinID;
  },
   
  },
};
</script>
