<template>
	<s-crud
		title="Incidencias de Canastilla"
		:filter="filter"
		:config="config"
		@save="save($event)"
		add
		edit
		@rowSelected="rowSelected($event)"
		remove
		search-input
	>
		<template v-slot:filter>
			<v-container>
				<v-row style="margin-left: 5px" justify="center">
					<v-col cols="6" md="6" lg="3">
						<s-date
							label="Fecha Inicio"
							v-model="filter.cDateInitial"
						></s-date>
					</v-col>
					<v-col cols="6" md="6" lg="3">
						<s-date
							label="Fecha Fin"
							v-model="filter.cDateFin"
						></s-date>
					</v-col>
					<v-col cols="6" lg="3">
						<s-select-definition
							clearable
							v-model="filter.TypeIncidence"
							:def="1216"
							label="Incidencia"
						></s-select-definition>
					</v-col>
				</v-row>
			</v-container>
		</template>
		<template scope="props">
			<v-container>
				<v-row>
					<v-col cols="12" lg="3" md="3">
						<s-select-layette
							v-model="props.item.LytID"
							label="Canastilla - En Proceso"
						></s-select-layette>
					</v-col>
					<!-- <v-col cols="3">
						<s-select-lot-production
							v-model="props.item.LprID"
							label="Lote -- Guia de Remisión"
							:estado="6"
							:lay="props.item.LayID"
						></s-select-lot-production>
					</v-col> -->
					<v-col cols="6" lg="3" md="3">
						<s-date
							label="Fecha/Hora Inicio"
							v-model="props.item.LinStarTime"
							type="datetime"
						/>
					</v-col>

					<v-col cols="6" lg="3" md="3">
						<s-date
							label="Fecha/Hora Fin"
							v-model="props.item.LinEndTime"
							type="datetime"
						/>
					</v-col>

					<v-col cols="6" lg="3" md="3">
						<s-select-definition
									v-model="props.item.TypeTurn"
									:def="1062"
									label="Turno"
								></s-select-definition>
					</v-col>

					<v-col cols="12" lg="6" md="6">
						<v-row>
							<v-col cols="12">
								<s-select-definition
									v-model="props.item.TypeIncidence"
									:def="1216"
									label="Incidencia"
								></s-select-definition>
							</v-col>
							<v-col cols="12" style="margin-top: -10px">
								<s-text
									v-model="props.item.LinReason"
									label="Causa de la Falla"
								/>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" lg="6" md="6">
						<s-textarea
							v-model="props.item.LinDescription"
							label="Descripción"
							:height="90"
						/>
					</v-col>
					<v-col cols="12">
						<b style="color: red"
							>Advertencia.. Las Canastillas apareceran siempre y
							cuando esten en proceso de tratamiento</b
						>
					</v-col>
				</v-row>
			</v-container>
		</template>
	</s-crud>
</template>

<script>
	import _sIncidence from "../../../services/FreshProduction/PrfLayetteIncidenceService.js";
	import SSelectLayette from "../../../components/FreshProduction/LayetteIncidence/SSelectLayette.vue";
	/* import SSelectLotProduction from "../../../components/FreshProduction/LayetteIncidence/SSelectLotProduction.vue"; */

	export default {
		components: { SSelectLayette },
		name: "PrfLayetteIncidence",
		data: () => ({
			selected: {},
			filter: {},
			config: {
				service: _sIncidence,
				model: {
					LinID: "ID",
					LinStarTime: "datetime",
					LinEndTime: "datetime"
				},
				headers: [
					{
						//
						text: "ID",
						value: "LinID",
						width: "3"
					},
					{
						text: "Canastilla",
						value: "LytID",
						 
					}
					,
					{
						text: "Turno",
						value: "TypeTurnName",
						 
					}, 
					{
						text: "Falla",
						value: "TypeIncidenceName",
						 
					},
					{
						text: "Hora Inicio",
						value: "LinStarTime",
						 
					},
					{
						text: "Hora Fin",
						value: "LinEndTime",
						 
					},
					{
						text: "Razón",
						value: "LinReason",
						 
					},

					{
						text: "Descripción",
						value: "LinDescription",
						 
					}
				]
			}
		}),

		methods: {
			rowSelected(item) {
				if (item.length > 0) {
					if (item !== undefined) {
						this.selected = item[0];
						console.log(this.selected);
					}
				}
			},

			save(item) {
				if (item.LytID == undefined) {
					this.$fun.alert("Seleccione una canastilla", "warning");
					return;
				}

				if (item.LinStarTime == null) {
					this.$fun.alert("Ingrese Fecha/Hora inicio", "warning");
					return;
				}

				if (item.LinEndTime !== null) {
					if (item.LinEndTime < item.LinStarTime) {
						this.$fun.alert(
							"Fecha Fin no puede ser menor a fecha de inicio",
							"warning"
						);
						return;
					}
				}

				if (item.LinReason.length == 0) {
					this.$fun.alert("Ingrese Causa de la Falla", "warning");
					return;
				}

				item.SecStatus = 1;
				item.UsrCreateID = this.$fun.getUserID();
				console.log("Guardar", item);

				item.save();

				/* const fecha = new Date();
					item.SecStatus = 1;
					if (item.LinDescription.length == 0) {
						this.$fun.alert("Registre descripción", "warning");
						return;
					}
					if (item.LinReason.length == 0) {
					}
					if (new Date(item.LinStarTime) < new Date(item.LinEndTime)) {
						item.save();
					} else {
						this.$fun.alert(
							"El rango de fechas y horas ingresados, no es válido",
							"warning",
						);
					} */
			}
		}
	};
</script>
